import React, {useEffect, useRef, useState} from "react";
import {Link} from "react-router-dom";
import ReactPlayer from 'react-player'

const sensitivity = 18;
let x1 = 0, y1 = 0, z1 = 0;
let localStep = 1;
let stop1 = false, stop2 = false, stop3 = false;
let lock = false;
let oldMax = 0, maxAcc = 0;
let sentRequest = false;
let tellTineOut = null;

function Constructor(){
    const intervalRef = useRef(null);
    const plRef = useRef();
    const [name, setName] = useState('');
    const [fixed, setFixed] = useState(false);
    const [data, setData] = useState({
        name: null,
        name_id: -1,
        wishes: []
    });

    const [paused, setPaused] = useState(true);
    const [step, setStep] = useState(0);
    const [generationStep, setGenerationStep] = useState(1);
    const [generating, setGenerating] = useState(false);
    const [result, setResult] = useState(false);
    const [finished, setFinished] = useState(false);
    const [videoUrl, setVideoUrl] = useState(null);
    const [error, setError] = useState(null);
    const [code, setCode] = useState(null);
    const [videoTapesOut, setVideoTapesOut] = useState(false);
    const [substep, setSubStep] = useState(1);

    useEffect(() => {
        const handleScroll = event => {
            const wrap = document.getElementById('cnst-bl');
            if(window.scrollY > (wrap.offsetHeight - window.outerHeight)){
                setFixed(true);
            }else{
                setFixed(false);
            }
        };

        window.addEventListener('scroll', handleScroll);

        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, []);

    useEffect(()=>{
        if(substep === 2){
            //document.body.classList.add('overflow');
        }else{
            document.body.classList.remove('overflow');
        }
    }, [substep]);

    const completeCongratulations = async(manual = false) => {
        if(manual){
            setPaused(false);
            setGenerationStep(4);
        }

        setGenerating(true);
        sentRequest = true;

        const formData = new FormData();
        if(data.name_id !== -1){
            formData.append("name", data.name_id);
        }
        formData.append("c1", data.wishes[0]);
        formData.append("c2", data.wishes[1]);
        formData.append("c3", data.wishes[2]);

        await fetch(process.env.REACT_APP_API_PROXY + 'completeCongratulation', {
            method: "POST",
            body: formData,
        }).then((response) => response.json()).then((response) => {
            if(response.result){
                setVideoUrl(response.data.link);
                setCode(response.data.code);
                setGenerating(false);
            }else{
                setGenerating(false);
                setError(response.error);

                if(finished){
                    setStep(4);
                }
                //reset();
            }
        });
    }

    const handleDeviceMotion = (event) => {
        x1 = event.accelerationIncludingGravity.x;
        y1 = event.accelerationIncludingGravity.y;
        z1 = event.accelerationIncludingGravity.z;

        if(!lock){
            startDetectMotion();
        }

        event.preventDefault();
        event.stopPropagation();
    }

    const startDetectMotion = () => {
        if(tellTineOut){
            clearInterval(tellTineOut);
        }
        tellTineOut = setInterval(() => {
            oldMax = maxAcc;
            maxAcc = Math.max(x1, y1, z1, oldMax);

            if (maxAcc >= sensitivity && !lock) {
                fireDetectNext();
            }
        });
    }

    const fireDetectNext = () => {
        if(!lock){
            lock = true;
            if(localStep === 1){
                if(!sentRequest){
                    completeCongratulations();
                }
                setGenerating(true);
            }

            setPaused(false);

            setTimeout(function (){
                lock = false;
                if(localStep < 4){
                    localStep++;
                    setGenerationStep(localStep);
                }
            }, 1000);
        }
    }

    const reset = async() => {
        setStep(0);
        setVideoUrl(null);
        setError(null);
        setFinished(false);
        setResult(false);
        setGenerationStep(1);
        setCode(null);
        setVideoTapesOut(false);
        setData({
            name: null,
            name_id: -1,
            wishes: []
        });
        tellTineOut = null;
        setName('');
        x1 = 0; y1 = 0; z1 = 0; oldMax = 0; maxAcc = 0;
        stop2 = false; stop3 = false;
        lock = false;
    }

    const shareVideo = async(title, url, video_url) => {
        const response = await fetch(video_url);
        const buffer = await response.blob();
        const video = new File([buffer], "greeting.mp4", { type: "video/mp4" });
        const files = [video];
        await navigator
            .share({
                files,
                text: title
            })
            .then(() => console.log("Shared successfully"))
            .catch((error) => console.error("Sharing failed:", error));
    }

    if(window.matchMedia("(min-width: 1024px)").matches){
        return(
            <div className="sc__constructor desk-only">
                <div className="container d-flex justify-content-center">
                    <div className="sc__promo-ct">
                        <Link to="/" className="logo">
                            <img src={require("../assets/img/logo.png")}/>
                        </Link>
                        <div className="sc__header d-flex justify-content-center">
                            <div className="sc__title-wrap">
                                <div className="sc__title gradient-text text-center">
                                    <span>Сайт доступен</span> <br/>
                                    для просмотра с мобильного устройства
                                </div>

                                <div className="star__light-decor star__light-decor--1"/>
                                <div className="star__light-decor star__light-decor--2"/>
                            </div>
                        </div>
                        <div className="sc__descr text-center">
                            для перехода отсканируйте QR-код <br/>
                            камерой вашего смартфона
                        </div>
                        <div className="lock__phone-box">
                            <div className="lock__phone-decor"/>
                            <div className="lock__qr">
                                <img src={require("../assets/img/lock-qr.png")}/>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }

    return(
        <div className="cnst__box">
            <div className="cnst__rotate-box d-flex align-items-center justify-content-center fadeIn animated">
                <div className="cnst__rotate-decor-1"/>
                <div className="cnst__rotate-decor-2"/>
                <div className="cnst__rotate-decor-3"/>
                <div className="cnst__rotate-ct">
                    <div className="cnst__rotate-img">
                        <img src={require("../assets/img/rotate-img.svg").default}/>
                    </div>
                    <div className="cnst__rotate-title text-center">
                        Поверните устройство <br/>
                        вертикально
                    </div>
                </div>
            </div>
            <a className="cnst__logo">
                <img src={require("../assets/img/logo.png")}/>
            </a>
            {/*<div className="debug__box">
                paused: {(paused ? 'true' : 'false')}&nbsp;&nbsp;&nbsp;change: {motionData.change}&nbsp;&nbsp;&nbsp;sens: {motionData.sensitivity} <br/>
                x1: {motionData.x1}  x2: {motionData.x2}&nbsp;&nbsp;&nbsp;
                y1: {motionData.y1} y2: {motionData.y2} <br/>
                z1: {motionData.z1} z2: {motionData.z2} <br/>

            </div>*/}
            <div id="cnst-bl"
                 className={'cnst__bl d-flex flex-wrap justify-content-between'
                     + (step === 0 ? ' align-content-between' : '')
                     + ((step === 1 || step === 3) ? ' align-content-start' : '')}
                 data-step={step}
            >
                {step === 0 && (
                    <>
                        <div className="box cnst__box-header d-flex justify-content-center fadeIn animated">
                            <div className="sc__title-wrap title--1">
                                <div className="end__title gradient-text">
                                    <span>Акция завершилась.</span> <br/>
                                    Призовой фонд будет доставлен победителям до <br className="mb-only"/>28 февраля 2025 года
                                </div>
                                <div className="end__subtitle">
                                    Остались вопросы? Напишите нам: <a href="mailto:korkunov@tma-draft.com">korkunov@tma-draft.com</a>
                                </div>
                                <div className="star__light-decor"/>
                            </div>
                        </div>
                    </>
                )}

                {step === 4 && (
                    <>
                        <div className="box fadeIn animated">
                            <div className="cnst__box-header">
                                <div className="d-flex justify-content-center">
                                    <div className="sc__title-wrap title--5">
                                        <div className="cnst__box-title gradient-text fadeIn animated">
                                            Упс!
                                        </div>
                                        <div className="star__light-decor"/>
                                    </div>
                                </div>
                                <div className="cnst__box-descr text-center">
                                    {error ?? ''}
                                </div>
                            </div>
                            <div className="box__back d-flex justify-content-center">
                                <div onClick={()=>{
                                    window.location.reload();
                                }} className="box__back-btn d-flex align-items-center">
                                    <i className="icon__back"/>
                                    <span className="gradient-text">Назад</span>
                                </div>
                            </div>
                        </div>
                    </>
                )}

                {step < 2 && (
                    <div className="video__box video__box--1 fadeIn animated">
                        <div className={'video__state' + (!data.name ? ' active' : '')}>
                            <ReactPlayer
                                url={require("../assets/video/step-0.mp4")}
                                playing
                                playsinline
                                loop
                                muted
                            />
                        </div>
                        <div className={'video__state video__state--2' + (data.name ? ' active' : '')}>
                            <ReactPlayer
                                url={require("../assets/video/step-1.mp4")}
                                playing={data.name}
                                playsinline
                                muted
                                onEnded={()=>{
                                    setVideoTapesOut(true);
                                }}
                            />
                        </div>
                        <div className={'video__state video__state--3' + (videoTapesOut ? ' active' : '')}>
                            <ReactPlayer
                                url={require("../assets/video/step-2.mp4")}
                                playing={videoTapesOut}
                                playsinline
                                loop
                                muted
                            />
                        </div>
                    </div>
                )}
            </div>
        </div>
    )
}

export default Constructor;
