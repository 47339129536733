import { Routes, Route } from 'react-router-dom'
import React from 'react';
import {ModalProvider} from "./context";
import Footer from './components/Footer'
import End from './pages/End'
import View from './pages/View'
import {Helmet} from "react-helmet";
import { TrackJS } from "trackjs";
import Test from "./pages/Test";
TrackJS.install({
    token: "a13b8cbc8a3943aba18078ce4216efbd",
    userId: "not_authorized"
});

function App() {
  return (
      <>
          <Helmet>
              <title>Чудеса в ваших руках</title>
              <meta property="og:title" content="Чудеса в ваших руках"/>
          </Helmet>
          <ModalProvider>
              <div id="wrapper" className="wrapper">
                  <Routes>
                      <Route path="/" element={<End />} />
                      <Route path="/view/:code" element={<View />} />
                      <Route path="/test" element={<Test />} />
                  </Routes>
                  <Footer/>
              </div>
          </ModalProvider>
      </>
  );
}

export default App;
